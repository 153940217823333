@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css");

html
{
    font-family: sans-serif;
}

.starter-template {
    padding: 3rem 1.5rem;
    text-align: center;
}

.my-container {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.body-bg {
    background: #F9F9F9;
}

.heading {
    font-size: 20px;
    margin-top: 16px;
    margin-bottom: 16px;
    line-height: 32px;
    font-style: normal;
    font-weight: 500;
    color: black;
    padding-right: 14px;
    padding-left: 14px;
}

.subheading {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.16px;
}

.subheading-step-1 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.6px;
    letter-spacing: 0.16px;
}

.logo {
    width: 150px;
    height: 133px;
}

.icon {
    width: 24px;
    height: 24px;
}

.mb-r2 {
    margin-bottom: 2rem;
}

.mb-r4 {
    margin-bottom: 4rem;
}

.mb-100px {
    margin-bottom: 100px;
}

.button {
    border-radius: 48px !important;
    background-color: #78B729 !important;
    border: none !important;
    height: 48px;
    line-height: 36px;
    padding-left: 16px;
    padding-right: 16px;
}

.button-arrow-right {
    background-image: url("/images/arrow.png");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: right 16px center;
    padding-right: 48px;
    padding-left: 12px;
    font-size: 16px;
}

.button-arrow-left {
    background-image: url("/images/arrow-left.png");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: left 16px center;
    padding-left: 48px;
    font-size: 16px;
}

.link-arrow-left {
    background-image: url("/images/arrow-left.png");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: left 16px center;
    padding-left: 48px;
    font-size: 16px;
    color: #78B729;
    font-weight: 500;
}

.button-statistic-active {
    background-image: url("/images/statistics-white.png");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: left 16px center;
    padding-left: 48px;
    font-size: 16px;
}

.button-statistic {
    background-image: url("/images/statistics-green.png");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: left 16px center;
    padding-left: 48px;
    font-size: 16px;
}

.button-posts-active {
    background-image: url("/images/posts-white.png");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: left 16px center;
    padding-left: 48px;
    font-size: 16px;
}

.button-posts {
    background-image: url("/images/posts-green.png");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: left 16px center;
    padding-left: 48px;
    font-size: 16px;
}


.button-profile-active {
    background-image: url("/images/profile-white.png");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: left 16px center;
    padding-left: 48px;
    font-size: 16px;
}

.button-profile {
    background-image: url("/images/profile-green.png");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: left 16px center;
    padding-left: 48px;
    font-size: 16px;
}

.button-wallet {
    background-image: url("/images/wallet.png");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: left 16px center;
    padding-left: 48px;
    font-size: 16px;
}

.button-wallet-white {
    background-image: url("/images/wallet-white.png");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: left 16px center;
    padding-left: 48px;
    font-size: 16px;
}

.button-wallet-white:disabled {
    background-image: url("/images/wallet-grey.png");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: left 16px center;
    padding-left: 48px;
    font-size: 16px;
}


.button-white {
    border-radius: 48px !important;
    background-color: white !important;
    border: solid 1px #78B729 !important;
    height: 48px;
    color: #78B729;
    line-height: 32px;
}

.button:disabled {
    background-color: rgba(0,0,0,0.12) !important;
    color: black !important;
}

.field {
    height: 46px !important;
}

a {
    font-size: 16px;
}

.mb-24 {
    margin-bottom: 24px;
}

.mb-44 {
    margin-bottom: 44px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mt-24 {
    margin-top: 24px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-32 {
    margin-top: 32px;
}

.pt-24 {
    padding-top: 24px;
}

.copy {
    position: absolute;
    right: 32px;
    top: 11px;
}

.header {
    background: #019D94;
    height: 64px;
    padding: 16px 16px 16px 16px;
}

.header-white {
    height: 64px;
    padding: 16px 16px 16px 16px;
}

.header-white .title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
}

.home-body {
    border-radius: 8px;
    margin-top: -8px;
    padding-bottom: 71px;
}

.qr {
    width: 153px;
    height: 153px;
}

.header .title {
    color: white;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
}

.stats-label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding-left: 12px;
    color: #78B729;
}

hr.dotted {
    border-top: 3px dotted #999;
}

.footer {
    font-size: 10px;
    line-height: 16px;
    width: 100%;
    height: 71px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: white;
}

.post-title {
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.5px;
    color: #019D94;
}

.post-date {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.32px;
    color: #666666;
}

.post-content {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.16px;
    color: #000000;
}

.add-post-label {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #000000;
}

.tariff-box {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #F4F4F4;
    border-radius: 12px;
}

.tariff-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #019D94;
}

.gray-text {
    color: #666666;
    font-size: 14px;
}

.price {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.5px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #000000;
}

.radio {
    display: none;
}

.tariffList {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding-left: 0;
    padding-right: 0;
}

.tariffItem {
    padding-left: 0;
    padding-right: 0;
}

.radioLabel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.tariffItemContent:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    display: block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px  solid #939393;
}

.radio:checked ~ .tariffItemContent {
    border: 2px  solid #78B729;
}

.radio:checked ~  .tariffItemContent:before {
    border-color: #78B729;
}

.radio:checked ~  .tariffItemContent:after {
    content: '';
    position: absolute;
    top: 14px;
    left: 14px;
    display: block;
    background-color: #78B729;
    height: 12px;
    width: 12px;
    border-radius: 50%;
}

.modal-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
}

.modal {
    display: none;
    position: fixed;
    z-index: 100;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

.modal-warning-content {
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    background-color: white;
}

@media (max-width: 767px) {
    .subheading {
        font-size: 14px;
    }
}
